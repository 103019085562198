import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { TextField } from '@material-ui/core';
import isHotkey from 'is-hotkey';
import { useEditItem2 } from '../../../api/useNewItem';
import { FormGrid, createTsv, useCopyText } from '../../primitives';


const matrixFromText = (text: string) => {
  const matrixChecks = text.split("\n")
    .map(l => l.trim())
    .filter(l => !!l)
    .map(l => l.split(/\s+/).map(s => s.trim()))
    .reduce<Record<string,Record<string, boolean>>>((res, [a,b]) => {
      if(!res[a]) {
        res[a] = {};
      }
      if(!res[b]) {
        res[b] = {};
      }
      res[a][b] = true;
      res[b][a] = true;
      return res;
    }, {});

  const keys = Object.keys(matrixChecks);
  const numKeys = keys.every(k => !isNaN(+k));
  const keysSorted = numKeys ? keys.sort((a,b) => +a < +b ? -1 : 1) : keys.sort();

  return {
    matrix: matrixChecks,
    keys: keysSorted,
  }
}

type Matrix = ReturnType<typeof matrixFromText>;

const useGridVisualizer = (cfg?: { startState?: string }) => {
  const [matrix,setMatrix] = useState<Matrix | null>(null);
  const [tsv,setTsv] = useState<string>("");

  const update = (text: string) => {
    const m = matrixFromText(text);
    setMatrix(m);
    const t = createTsv([" ", ...m.keys], k1 => [k1, ...m.keys.map(k2 => m.matrix[k1][k2] ? "x" : " ")], m.keys);
    setTsv(t);
  }

  const data = useEditItem2<{ pairsText: string }>({
    startWith: { pairsText: "" },
    dontResetOnSave: true,
    save: item => {
      update(item.pairsText);
      return Promise.resolve(item);
    }
  });

  const startState = cfg?.startState;

  useEffect(() => {
    if(startState) {
      data.update({ pairsText: startState });
      update(startState);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startState])

  return {
    data,
    matrix,
    tsv,
  }
}

type GridVisualizerData = ReturnType<typeof useGridVisualizer>;


const GridBox = styled.table`
  border-collapse: collapse;
  table-layout: fixed;

  & tr {
    &:nth-child(2n) {
      background: #eeeeee;
    }
  }

  & td, th {
    border: 0.5px solid #000000a0;
    text-align: center;

    width: 1.5rem;
    max-width: 1.5rem;
    height: 1.5rem;

    &:nth-child(2n) {
      background: #eeeeee;
    }
  }
`;


interface Props {
  data: GridVisualizerData;
}

export const GridVisualizerUnbound = (props: Props) => {
  const { data, matrix, tsv } = props.data;
  const copyText = useCopyText();

  return (
    <FormGrid columns="200px 1fr">
      <TextField
        value={data.item?.pairsText || ""}
        onChange={e => data.update({ pairsText: e.target.value })}
        multiline
        onKeyPress={e => {
          if(isHotkey("enter")(e)) {
            data.save();
          }
        }}
        />

      {!!matrix &&
        <GridBox
          style={{ width: `calc(1.5rem * ${matrix.keys.length + 1})`}}
          onClick={() => copyText(tsv)}>
          <tr>
            <th></th>
            {matrix.keys.map(k => <th>{k}</th>)}
          </tr>
          
          {matrix.keys.map(kRow => (
            <tr key={kRow}>
              <th>{kRow}</th>
              {matrix.keys.map(kCol => (
                <td>{matrix.matrix[kCol][kRow] ? <span>x</span> : null}</td>
              ))}
            </tr>
          ))}
        </GridBox>}
    </FormGrid>
  );
}

export const GridVisualizer = (props: { startState?: string }) => {
  const data = useGridVisualizer({ startState: props.startState });
  return <GridVisualizerUnbound data={data} />
}
