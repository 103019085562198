import React, {  } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { LoadingIndicator } from '../../../toolympus/components/primitives/LoadingIndicator';
import { DocumentAssessmentListData } from './useDocumentsAssessmentList';
import { DocumentNames } from '../../Team/useTeamDocuments';
import { FormattedMessage } from 'react-intl';
import { FormGrid } from '../../../toolympus/components/primitives/Forms';
import { FormControlsForFields } from '../../../toolympus/components/schemed';
import { FieldType } from '../../../toolympus/hooks/useSchema';


interface PenaltyEditProps {
    data: DocumentAssessmentListData["penaltyEdit"];
}

export const PenaltyEditPopup = (props: PenaltyEditProps) => {
    const { item, update, errors, isEditing, isLoading, cancel, save } = props.data;
    return (
        <Dialog
            open={isEditing}
            onClose={() => save()}
            fullWidth
            maxWidth="sm">
            <DialogTitle>
                Штраф для документа {item?.kind && DocumentNames[item?.kind as 'doc1' | 'doc2']} команды {item?.team_number}
            </DialogTitle>
            <DialogContent>
                {item && <FormGrid columns="1fr">
                        <FormControlsForFields
                            data={item}
                            onChange={(o,c) => update(c)}
                            fields={[
                                ['points', { is_float: true }],
                                ['comment'],
                            ]}
                            schema={{
                                points: { type: FieldType.number, label: 'Штраф', },
                                comment: { type: FieldType.textlong, label: 'Комментарий', },
                            }}
                            errors={errors}
                        />
                    </FormGrid>}
            </DialogContent>
            <DialogActions>
                <Button onClick={cancel}><FormattedMessage id="common.cancel" /></Button>
                <Button variant="contained" color="primary" onClick={() => save()} disabled={isLoading}><FormattedMessage id="common.save" />{isLoading && <LoadingIndicator />}</Button>
            </DialogActions>
        </Dialog>
    )
}
