import React from 'react';
import styled from '@emotion/styled';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { FormGrid } from '../../primitives/Forms';
import { LoadingIndicator } from '../../primitives/LoadingIndicator';
import { FieldDefinition, FormControl } from '../../schemed';
import { RoundsSelectedText, TimezoneSetting } from './Common';
import { MassEditRoundData } from './useMassEditRounds';
import { useGridSettings } from './GridSettingsContext';

interface Props {
    data: MassEditRoundData;
    roundsTimeZone?: TimezoneSetting;
}

const CheckboxWrapper = styled.div`
    padding-top: 16px;
`;

export const MassEditRoundsPopup = (props: Props) => {
    const {
        isEditing,
        cancel,
        save,
        isSaving,
        changes,
        update,
        schema,
        hasChanges,
        selectedRounds,
    } = props.data;

    const { conference_kind } = useGridSettings();

    const control = (fd: FieldDefinition) => (
        <FormControl
            field={fd[0]}
            schema={schema[fd[0]]}
            row={changes}
            onChange={(o,c) => update(c)}
            extraProps={fd[1]}
            />
    )

    return (
        <Dialog open={isEditing} onClose={cancel} fullWidth maxWidth="sm">
            <DialogTitle>
                <FormattedMessage id="contests.rounds.config.stages.edit_rounds.title" />
            </DialogTitle>
            <DialogContent>
                <Typography variant="caption"><RoundsSelectedText rounds={selectedRounds} /></Typography>

                <FormGrid columns="max-content 1fr" style={{ columnGap: 0 }}>
                    <CheckboxWrapper key="cbTitle">
                        <Checkbox
                            size="small"
                            checked={changes.isTitleSet || false}
                            onChange={e => update({ isTitleSet: e.target.checked })}
                            />
                    </CheckboxWrapper>
                    {control(["title"])}

                    <CheckboxWrapper key="cbTime">
                        <Checkbox
                            size="small"
                            checked={changes.isTimeSet || false}
                            onChange={e => update({ isTimeSet: e.target.checked })}
                            />
                    </CheckboxWrapper>
                    {control(["time", { utcToLocal: props.roundsTimeZone === "utc" ? false : true }])}
                    
                    {conference_kind === "zoom" && <>
                        <CheckboxWrapper key="cbJoinUrl">
                            <Checkbox
                                size="small"
                                checked={changes.isJoinUrlSet || false}
                                onChange={e => update({ isJoinUrlSet: e.target.checked })}
                                />
                        </CheckboxWrapper>
                        {control(["join_url"])}
                    </>}

                    <CheckboxWrapper key="cbDescription">
                        <Checkbox
                            size="small"
                            checked={changes.isDescriptionSet || false}
                            onChange={e => update({ isDescriptionSet: e.target.checked })}
                            />
                    </CheckboxWrapper>
                    {control(["description"])}
                </FormGrid>
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" disabled={!hasChanges} onClick={() => save()}>
                    {isSaving && <LoadingIndicator size="small" />} <FormattedMessage id="common.save" />
                </Button>
                <Button onClick={cancel}><FormattedMessage id="common.cancel" /></Button>
            </DialogActions>
        </Dialog>
    );
}
