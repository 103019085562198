import { Typography } from '@material-ui/core';
import React, { ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { apiFetch, FetchTypes } from '../../../api/core';
import { useAction } from '../../../api/useAction';
import { SimpleDialog } from '../../primitives/Dialogs';
import { useAvailableTags } from '../../Tags';
import { RecordTagEditProps, RecordTagsEdit, useTagSelection } from '../../Tags/RecordTagsEditPopup';
import { Round } from './types';
import { EditItemProps, useEditItem2 } from '../../../api/useNewItem';
import { FormGrid } from '../../primitives';
import { FormControlsForFields } from '../../schemed';
import { FieldType, Schema, createSelectSchema } from '../../../hooks/useSchema';

interface PopupProps {
    isOpen: boolean;
    close: () => void;
    run: () => Promise<any>;
    isRunning: boolean;
    includeTags: RecordTagEditProps;
    excludeTags: RecordTagEditProps;
    paramsEdit: EditItemProps<AssignmentParams>;
    roundsSelected?: number;
}


interface AssignmentParams {
  arbiters_per_round: number | null;
  max_assignments_per_arbiter: number | null;
  keep_order: boolean;
  keep_existing: boolean;
}

const AssignmentParamsSchema: Schema = {
  arbiters_per_round: createSelectSchema(
    [1,2,3].map(value => ({ value, label: value.toString() })),
    { label_id: "contests.rounds.config.stages.arbiters_per_round", type: FieldType.select },
  ),
  max_assignments_per_arbiter: createSelectSchema(
    [1,2,3,4,5].map(value => ({ value, label: value.toString() })),
    { label_id: "contests.rounds.config.stages.max_assignments_per_arbiter" },
  ),
  keep_order: { label_id: "contests.rounds.config.stages.keep_order", type: FieldType.bool },
  keep_existing: { label_id: "contests.rounds.config.stages.keep_existing", type: FieldType.bool },
}

export const AssignArbitersPopup = (props: PopupProps) => {
    const { isOpen, close, run, includeTags, excludeTags, paramsEdit, } = props;
    return (
        <SimpleDialog
            isOpen={isOpen}
            close={close}
            fullWidth maxWidth="lg"
            dialogTitle={<FormattedMessage id="contests.rounds.config.stages.assign_arbiters" />}
            save={run}
            saveLabel={<FormattedMessage id="contests.rounds.config.stages.assign_arbiters" />} >

            {!!props.roundsSelected &&
              <Typography><FormattedMessage id="contests.rounds.config.stages.assigning_to_n_rounds" values={{ n: props.roundsSelected }}/></Typography>}

            {paramsEdit.item &&
              <FormGrid noMargin columns="2fr 2fr 2fr 2fr" style={{ alignItems: "end" }}>
                <FormControlsForFields
                  data={paramsEdit.item}
                  schema={AssignmentParamsSchema}
                  onChange={(o,c) => paramsEdit.update(c)}
                  fields={[
                    ["arbiters_per_round"],
                    ["max_assignments_per_arbiter"],
                    ["keep_existing"],
                    ["keep_order"],
                  ]}

                  />
              </FormGrid>}
            
            <FormGrid columns="1fr 1fr" forceEvenColumns>
              <FormGrid columns="1fr" noMargin gap="dense">
                <Typography variant="caption" color="textSecondary"><FormattedMessage id="contests.rounds.config.stages.include_tags" /></Typography>
                <RecordTagsEdit {...includeTags} wrapTags noDisplaySelected stretchSearchField />
              </FormGrid>

              <FormGrid columns="1fr" noMargin gap="dense">
                <Typography variant="caption" color="textSecondary"><FormattedMessage id="contests.rounds.config.stages.exclude_tags" /></Typography>
                <RecordTagsEdit {...excludeTags} wrapTags noDisplaySelected stretchSearchField />
              </FormGrid>

            </FormGrid>
            {!paramsEdit.item?.keep_existing && <Typography><FormattedMessage id="contests.rounds.config.stages.assign_arbiters_confirmation" /></Typography>}

        </SimpleDialog>
    );
}


export interface ArbitersAssignment {
    popup: ReactNode;
    open: () => void;
}

interface Config {
  onSave?: () => void;
  selectedRounds?: Round[];
}


export const useArbitersAssignment = (apiPath: string, stage_code: string, cfg?: Config): ArbitersAssignment => {
    const [isActive, setIsActive] = useState<boolean>(false);
    const { tags: allTags } = useAvailableTags();
    const includeTags = useTagSelection(allTags);
    const excludeTags = useTagSelection(allTags);

    const paramsEdit = useEditItem2<AssignmentParams>({
      startWith: { arbiters_per_round: null, max_assignments_per_arbiter: null, keep_order: false, keep_existing: true },
    });

    const open = () => {
        setIsActive(true);
        includeTags.clear();
        excludeTags.clear();
    }

    const close = () => {
        setIsActive(false);
        includeTags.clear();
        excludeTags.clear();
    }

    const run = useAction(() => {
      const data: any = {
        include: includeTags.selectedTags.map(t => t._id),
        exclude: excludeTags.selectedTags.map(t => t._id),
        ...(paramsEdit.item || {}),
      };
      
      if(cfg?.selectedRounds && cfg?.selectedRounds.length) {
        data.rounds_ids = cfg.selectedRounds.map(r => r._id);
      }

      return apiFetch(`${apiPath}/stage/${stage_code}/round/assign-arbiters-advanced`, FetchTypes.POST, data)
        .then(() => {
          close();
          if(cfg?.onSave) {
              cfg.onSave();
          }
      })
    });

    return {
        open,
        popup: <AssignArbitersPopup
            close={close}
            isOpen={isActive}
            run={run.run}
            isRunning={run.isRunning}
            includeTags={includeTags}
            excludeTags={excludeTags}
            paramsEdit={paramsEdit}
            roundsSelected={cfg?.selectedRounds?.length || 0}
            />,
    }
}