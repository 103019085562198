import React from 'react';
import styled from '@emotion/styled';
import { useCrudItem } from '../../../toolympus/api/useSimpleCrud';
import { Button, Chip, IconButton, Typography } from '@material-ui/core';
import { formatSlotDayDisplay, formatSlotTimeDisplay, useTimeslotsConfig } from './useTimeslotsConfig';
import { ActionRow, FormGrid, LoadingIndicator, OccupyFreeSpace, SaveButton, SimpleDialog } from '../../../toolympus/components/primitives';
import { DateTimeEdit } from '../../../toolympus/components/schemed/TimeEdit';
import { Add } from '@material-ui/icons';
import { useSaveable } from '../../../toolympus/components/primitives/useSaveable';

export const TimeslotsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 0.5rem;
    
    & > * {
        margin: 0.25rem 0;
    }
`;

interface WithTimeslots {
    rounds_timeslots: string[] | null;
}

export const TimeslotsConfig = () => {
    const { data, update, save, hasChanges, isLoading } = useCrudItem<WithTimeslots>('/api/state', {
        defaultValue: { rounds_timeslots: null },
    });

    const timeslots = useTimeslotsConfig({
        timeslots: data.rounds_timeslots,
        updateTimeslots: v => update({ rounds_timeslots: v }),
    });

    useSaveable({ save, hasChanges });
    

    return (
        <div>
            <ActionRow>
                {isLoading && <LoadingIndicator sizeVariant="s" />}
                <OccupyFreeSpace />
                {hasChanges && <SaveButton action={() => save()} />}
            </ActionRow>
            <FormGrid columns="max-content max-content 1fr" style={{ alignItems: "center" }}>
                {timeslots.days.map(d => <React.Fragment key={d.toString()}>
                    <Typography>{formatSlotDayDisplay(d)}</Typography>
                    <IconButton size="small" onClick={() => timeslots.startAddTimeslot(timeslots.timeslotsByDay[d.toString()].slice(-1)[0])}><Add /></IconButton>

                    <TimeslotsWrapper>
                        {timeslots.timeslotsByDay[d.toString()].map(ts => <Chip label={formatSlotTimeDisplay(ts)} onDelete={() => timeslots.removeTimeslot(ts)} />)}
                    </TimeslotsWrapper>

                </React.Fragment>)}

            </FormGrid>

            <Button onClick={() => timeslots.startAddTimeslot()}>добавить слот</Button>

            <SimpleDialog
                dialogTitle="Слот"
                noFullscreen
                maxWidth="xs"
                saveLabel="добавить"
                isOpen={timeslots.isAddingTimeslot}
                close={timeslots.cancelAddTimeslot}
                save={timeslots.finishAddTimeslot}>
                <DateTimeEdit
                    field="slot"
                    row={{ slot: timeslots.newTimeslot}}
                    onChange={(o,c) => timeslots.setNewTimeslot(c.slot)}
                    schema={{ label: " "}}
                    />
            </SimpleDialog>
        </div>
    );
}
