import React from 'react';
import { useCrudItem } from '../../../toolympus/api/useSimpleCrud';
import { OccupyFreeSpace } from '../../../toolympus/components/primitives/ActionRow';
import { SaveButton } from '../../../toolympus/components/primitives/Buttons';
import { Form, FormGrid } from '../../../toolympus/components/primitives/Forms';
import { LoadingIndicator } from '../../../toolympus/components/primitives/LoadingIndicator';
import { TabPanel, TabsHeader, useTabsState } from '../../../toolympus/components/primitives/Tabs';
import { useSaveable } from '../../../toolympus/components/primitives/useSaveable';
import { FieldDefinition, FormControlsForFields, PlaceholderField } from '../../../toolympus/components/schemed';
import { useSchema } from '../../../toolympus/hooks/useSchema';
import { ContestState, DefaultContestState } from '../../../useContestState';
import { ConflictsControlPage } from './ConflictsControlPage';
import { TimeslotsConfig } from './TimeslotsConfig';

export const StateControlPage = () => {
    const { data, update, save, hasChanges, isLoading } = useCrudItem<ContestState>('/api/state', {
        defaultValue: DefaultContestState,
    });
    const { state: schema } = useSchema();

    const controls = (fields: FieldDefinition[]) => (
        <FormControlsForFields
            fields={fields}
            data={data}
            onChange={(o,c) => update(c)}
            schema={schema}
            />
    );

    const tabs = useTabsState([
        ["control", "Управление"],
        ["conflicts", "Теги конфликтов"],
        ["timeslots", "Время раундов"],
    ], "control", "tab");

    useSaveable({ save, hasChanges });

    return (
        <Form
            headerItems={<>
                {isLoading && <LoadingIndicator sizeVariant="s" />}
                <OccupyFreeSpace />
                {hasChanges && <SaveButton action={() => save()} />}
            </>}>

            <TabsHeader state={tabs} />

            <TabPanel state={tabs.forTab("control")}>
                <FormGrid columns="1fr 1fr">
                    {controls([
                        ["registration_available"],
                        ["registration_available_judge"],
                        ["registration_available_secretary"],
                        ["team_registration_due_date"],
                        ["case_available"],
                        [PlaceholderField],
                        ["documents_upload_available"],
                        ["documents_upload_allowed"],
                        ["documents_due_date"],
                        [PlaceholderField],
                        ["documents_assessment_available"],
                        ["documents_assessment_allowed"],
                        ["documents_scores_available"],
                        ["finals_members_update_enabled"],
                        ["documents_feedback_pass_available"],
                        ["documents_feedback_non_pass_available"],
                        ["finals_available"],
                        ["finals_results_available"],
                        ["finals_broadcast_page_available"],
                        [PlaceholderField],
                        ["judges_timeslots_available"],
                        ["judges_timeslots_enabled"],
                        ["rounds_feedback_available"],
                    ])}
                </FormGrid>
            </TabPanel>

            <TabPanel state={tabs.forTab("conflicts")}>
                <ConflictsControlPage />
            </TabPanel>

            <TabPanel state={tabs.forTab("timeslots")}>
                <TimeslotsConfig />
            </TabPanel>
        </Form>
    );
}
