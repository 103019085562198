import React, { ReactElement, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { cols, FormGrid } from '../../toolympus/components/primitives/Forms';
import { FieldDefinition, FormControlsForFields } from '../../toolympus/components/schemed';
import { LoadingIndicator } from '../../toolympus/components/primitives/LoadingIndicator';
import { Blank } from '../Common.styles';
import { JudgeReview } from './useJudgeReview';
import { Tooltip } from '../../toolympus/components/primitives/Tooltip';
import { GoToChatButton } from './Common';
import { RecordsTags } from '../../toolympus/components/Tags';
import { TimeslotsDisplay } from '../Judge/JudgeTimeslotsSelection';


interface Props {
    data: JudgeReview;
    conflicts: RecordsTags;
}

export const JudgeReviewPopup = (props: Props) => {
    const { isOpen, close, data: judge, schema, isLoading, approve, reject, comments, update, isSaving, hasChanges, save, } = props.data;

    const [isApproving, setIsApproving] = useState<boolean>(false);
    const [isRejecting, setIsRejecting] = useState<boolean>(false);

    const controls = (fields: FieldDefinition[], withArea?: boolean) => (
        judge && <FormControlsForFields
            data={judge}
            schema={schema}
            fields={fields}
            onChange={(o,c) => update(c)}
            withArea={withArea}
            />
    )

    return (
        <>
        <Dialog open={isOpen} onClose={close} fullWidth maxWidth="lg">
            <DialogContent>
                <DialogTitle>
                    Судья
                    <Tooltip text="Комментарии">
                        {comments.button as ReactElement<any, any>}
                    </Tooltip>
                    <Tooltip text="Чат" withWrapper>
                        <GoToChatButton to={`/admin/chat-org/${judge?._id}`} />
                    </Tooltip>
                    {isLoading && <LoadingIndicator />}
                </DialogTitle>
                <FormGrid columns="1fr 1fr">
                    {controls([["email", { disabled: true }]])}
                </FormGrid>

                <Blank />
                <Blank />
                <Typography variant="h6">Общая информация</Typography>
                <FormGrid
                    columns={cols('1fr', 6)}
                    areas={[
                        `${cols("lastname", 2)} ${cols("firstname", 2)} ${cols("middlename", 2)}`,
                        `${cols("phone", 3)} ${cols("city", 3)}`,
                        cols("education", 6),
                        cols("job", 6),
                        cols("experience", 6),
                        cols("affiliation", 6),
                    ]}>
                    {controls([
                        ["lastname"],
                        ["firstname"],
                        ["middlename"],
                        ["phone"],
                        ["city"],
                        ["education"],
                        ["job"],
                        ["experience"],
                        ["affiliation"],
                    ], true)}
                </FormGrid>
                
                <Blank />
                <Typography variant="h6">Конфликты</Typography>
                {judge && props.conflicts.displayForRecord(judge?._id)}

                <Blank />
                <Blank />

                <Typography variant="h6">Выбранные слоты</Typography>
                {(judge?.timeslots_selected || []).length === 0 && "-"}
                {(judge?.timeslots_selected || []).length > 0 && <TimeslotsDisplay slots={judge?.timeslots_selected} />}

                <Blank />
                <Blank />

                <Typography variant="h6">Опыт и участие в конкурсе</Typography>
                <FormGrid
                    columns={cols("1fr", 2)}
                    areas={[
                        cols("experience_moots", 2),
                        cols("experience_femida_title", 2),
                        "experience_femida_member experience_femida_coach",
                        "experience_femida_judge_written experience_femida_judge_oral",
                        cols("judge_intention_title", 2),
                        "judge_intention_written judge_intention_oral",
                        cols("judge_written_works_nbr_title", 2),
                        "judge_written_works_nbr save",
                        cols("travel_compensation_required", 2),
                        cols("personal_data_consent", 2),
                    ]}>
                    {controls([
                        ["experience_moots"],
                        ["experience_femida_member"],
                        ["experience_femida_coach"],
                        ["experience_femida_judge_written"],
                        ["experience_femida_judge_oral"],
                        ["judge_intention_written"],
                        ["judge_intention_oral"],
                        ["judge_written_works_nbr"],
                        ["travel_compensation_required"],
                        ["personal_data_consent", { disabled: true }],
                    ], true)}
                    <Typography variant="caption" style={{ gridArea: "experience_femida_title" }}>Опыт участия в Конкурсе "Хрустальная Фемида"</Typography>
                    <Typography variant="caption" style={{ gridArea: "judge_intention_title" }}>Вы хотели бы принять участие в качестве судьи</Typography>
                    <Typography variant="caption" style={{ gridArea: "judge_written_works_nbr_title" }}>Пожалуйста, укажите, сколько письменных работ Вы сможете оценить (как правило, мы просим судей проверить не менее двух работ: одну жалобу и один отзыв)</Typography>
                    <Typography style={{ gridArea: "empty2" }}> </Typography>
                </FormGrid>

            </DialogContent>
            <DialogActions>
                <Button onClick={close}>
                    закрыть
                </Button>
                {hasChanges &&
                    <Button variant="contained" color="primary" onClick={() => save()}>
                        {isSaving && <LoadingIndicator sizeVariant="s" />} сохранить
                    </Button>}
                {!hasChanges && <>
                    <Button variant="contained" color="secondary" onClick={() => setIsRejecting(true)}>
                        отказать
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => setIsApproving(true)}>
                        утвердить
                    </Button>
                    </>}
            </DialogActions>
        </Dialog>

        <Dialog open={isApproving} onClose={() => setIsApproving(false)} fullWidth maxWidth="sm">
            <DialogContent>
                <Typography>Судье будет обновлён статус и отправлено уведомление об утверждении (judge-approved).</Typography>
                <Typography>Продолжить?</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setIsApproving(false)}>
                    отмена
                </Button>
                <Button variant="contained" color="primary" onClick={() => approve().then(() => { setIsApproving(false); })}>
                    утвердить
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog open={isRejecting} onClose={() => setIsRejecting(false)} fullWidth maxWidth="sm">
            <DialogContent>
                <Typography>Судья будет перенесен в список "Отказ". Уведомление отправляться не будет.</Typography>
                <Typography>Продолжить?</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setIsRejecting(false)}>
                    отмена
                </Button>
                <Button variant="contained" color="primary" onClick={() => reject().then(() => { setIsRejecting(false); })}>
                    отказать
                </Button>
            </DialogActions>
        </Dialog>
        </>
    );
}
