export const enMessages = {
  error: {
      general: "Something bad happened",
      validation: "Some fields contain errors",
      noRights: "You don't have the rights to execute this action",

      files: {
          content_type_not_allowed: "This file type is not allowed. Allowed file types are: {allowed_types}",
          file_too_large: "File is too large. Maximum allowed file size is {max_size_kb}kb",
          alias_occupied: "This alias is already occupied",
      },

      auth: {
          login_taken: "A user with this email already exists",
          password_too_weak: "The password should be 8 characters or longer and should contain all of: lowercase and uppercase characters and digits",
          failed_login: "Incorrect login or password",
          code_expired: "The code has expired. To get a new code, please, refresh the page",
          code_invalid: "The code is incorrect. To get a new code, please, refresh the page",
          invite_code_invalid: "Invite code is incorrect. Possibly the invite link has expired. Please check your email for a newer link",
          confirmation_code_invalid: "Confirmation code is incorrect. Possibly the confirmation link has expired. Please check your email for a newer link",
          reset_token_invalid: "Password reset token is incorrect. Possibly the reset link has expired. Please check your email for a newer link or request it again",
          user_already_confirmed: "User's email is already confirmed",
          user_doesnt_need_confirmation: "User's email doesn't need confirmation",
          user_blocked: "The user is blocked. This may happen if you enter invalid credentials several times in a row. In this case authorization is blocked for {minutes} minutes. If you think this is a mistake, please get in touch with the administrator",
          passwords_dont_match: 'Passwords do not match',
      },

      dictionary: {
          cant_update_synthetic_dictionary: "Can't update synthetic dictionary - update the value in the source dictionary",
      },

      export: {
          invalid_code: "Export config not found",
      },
  },
  success: {
      clipboard: "Value copied to clipboard"
  },
  login: {
      email: "Email",
      password: "Password",
      code: "Code",
      code_hint: "We've sent you a 5-digit code. Please enter it to finish authentication",
      submit: "Login",
      errorcreds: "The credentials are invalid. Please try again or reset your password if you forgot it"
  },
  signup: {
      errorMessage: "Something bad happened",
      successMessage: "The signup request has been sent. You will get access, once the administrator approves it",
      email: "Email",
      password: "Password",
      passwordRepeat: "Repeat password",
      submit: "Sign up"
  },
  signupConfirmation: {
      defaultMessage: "Confirming email...",
      successMessage: "Email confirmed successfully. Thank you!",
      errorMessage: "Something went wrong. Please get in touch with us."
  },
  resetPassword: {
      email: "Email",
      submit: "Reset password",
      success: "We've sent you an email with a link. Please follow that link to create a new password.",
  },
  restorePassword: {
      password: "Password",
      passwordRepeat: "Repeat password",
      submit: "Change password",
      success: "Password was changed successfully. Please log in with the new password",
  },
  emailConfirmation: {
      confirming: "Confirming the Email...",
      success: "Your Email was successfully confirmed. Thank you!",
      failure: "An error happenned. Please try reloading the page.",
  },
  searchModal: {
      title: "Search",
      searchFieldLabel: "Find...",
      pickItem: "Pick",
      emptyState: "Nothing has been found"
  },
  searchField: {
      label: "Find...",
      buttonLabel: "Load",
  },
  userManagement: {
      title: "Users",
      header: {
          addUser: "Add user",
          sendInvites: "Send invites",
          sendInvintesTip: "Invites will be sent only to new users, no repeats",
          export_users: "Download user list",
      },
      columns: {
          email: "Email",
          lastLogin: "Last Login",
          blocked_until: "Block",
          blocked_until_explanation: "The user is blocked and won't be able to log in until the specified time",
          unblock: "Unblock user",
          block: "Block user",
          invited: "Invited",
          confirmed: "Confirmed",
          two_factor_auth: "Two-factor auth",
          resendConfirmRequest: "Resend confirmation request",
          repeatedConfirmRequestNever: "Confirmation request was not repeated",
          repeatedConfirmRequestTime: "Confirmation request was repeated at {time}",
          invite: "Invite",
          signInAs: "Sign in",
          sendPasswordResetToken: "Send password reset code",
          change_password: "Change password",
      },
      userPopup: {
          title: "Add users",
          email: "Email",
          submit: "Create",
          hint: "enter emails of the users to be created, separated with whitespaces or semicolons"
      },
      changeEmailPopup: {
          title: "Change Email",
          oldEmail: "Current Email",
          newEmail: "New Email",
      },
      change_password_popup: {
          title: "Change user's password",
          new_password: "New password",
          success: "The password has been changed",
      },
      two_factor_auth_popup: {
          title: "Two-factor auth",
          medium: "Second factor",
          two_factor_user_id: "User ID",
          two_factor_user_id_hint: "ID or address of the user in the second factor app",
      },
      password_reset_email_sent: "Password change code was sent to the user",
      copy_email: "Copy Email",
      copy_id: "Copy ID",
  },
  auth: {
    apitokens: {
      title: "API Tokens",
      create: "Create a token",
      token_created_hint: "Save the created token - it get's shown only once",

      block_token: "Block the token",
      block_token_hint: "When you block a token, applications using it to access the system immediately loose access. A blocked tocken cannot be unblocked, but you may always create a new one.",
      block: "Block",

      fields: {
        _id: "ID",
        email: "E-mail",
        roles: "Roles",
        created_datetime: "Created",
        is_blocked: "Blocked",
        token: "Token",
      },
    },
  },

  emails: {
      screen_title: "Email templates",
      fields: {
          code: "Template",
          base_template: "Base template",
          title: "Title",
          description: "Description",
          subject: "Subject",
          body: "Body",
          body_html: "HTML Body",
          autowrap_html_body: "Auto-wrap HTML body",
          active: "Active",
      },

      popup: {
          title: "Edit Template",
          tab_content: "Content",
          tab_settings: "Targets and settings",
          emailContent: "Email content",
          testEmail: "Send a test email",
          testEmailTextTip: "Parameters in JSON format",
          powertemplate_hint: "Use new editor",
          to: "Address",
          send: "Send",
          delete: "Delete the template?",
          textHint: "Plain text",
          htmlHint: "HTML",
          preview_html: "Preview HTML",
          scriptable_block: "Block",
      },

      error: {
        email_template_not_found: "Email template not found",
      },
      
      action_send_email: {
        title: "Send email",
        description: "Send an email by template. The To param may use {{field.in.data}} placeholder syntax",
        params: {
          template: "Template",
          to: "To",
        },
      },
  },
  dictionaries: {
      title: "Dictionaries",
      statusSaved: "Saved",
      newRecordLabel: "New record code",
      addRecord: "Add",
      selectDictionary: "Dictionary",
      import_success: "Import succeeded",

      remove_title: "Delete record",
      remove_hint: "The record will be removed from the dictionary. If it is already being used, removal may result in data inconsistency and runtime issues across the system.",

      fields: {
          code: "Code",
          label: "Label",
          label_lang: "Label ({lang})",
          label_translations: "Label translations",
          sortorder: "Order",
          comment: "Comment",
          disabled: "Disabled",
      }
  },
  queryconsole: {
      screen_title: "Query console",
      title: "Query title",
      query: "Query",
      run: "Run",
      history: "History",
  },

  eventlog: {
      screen_title: "Event log",
      labels: {
          _id: "ID",
          kind: "Type",
          message: "Event",
          created_at: "Timestamp",
          time_from: "From",
          time_to: "To",
          total_rows: "Events found:",
      },

      actions: {
        event_log_action: {
          title: "Event log",
          description: "Creates an event log record with the specified kind",
          kind: "Message kind",
          keys: "Data keys to log",
        },
      },
  },

  schemas: {
      title: "UI Schemas",
  },

  localization: {
      messages: {
          title: "Localized messages",
          add_language: "Add language",
          language_code: "Language code",
          json_error: "JSON formatting error",
          add_message: "Add message",

          show_empty_only: "Show empty only",

          pull_messages_from_other_lang: "Pull messages",
          pull_messages_from_other_lang_hint: "from another language",
          pull_messages_from_other_lang_select_lang: "Select the language to pull messages from",

          mode: {
              strings: "messages",
              json: "json",
          },

          table: {
              key: "Code",
              message: "Message",
          },
      },
  },

  common: {
      cancel: "Cancel",
      close: "Close",
      add: "Add",
      delete: "Delete",
      edit: "Edit",
      replace: "Replace",
      save: "Save",
      send: "Send",
      submit: "Submit",
      change: "Change",
      yes: "Yes",
      no: "No",
      select: "Select",
      select_all: "Select all",
      deselect: "Deselect",
      copy: "Copy",
      paste: "Paste",
      clear: "Clear",
      download: "Download",
      upload: "Upload",
      create: "Create",
      reload: "Reload",
      expand_all: "Expand all",

      show_details: "show details",
      hide_details: "hide details",

      fullscreen: "Fullscreen mode",

      invalid_date_format: "Invalid date format",

      copy_as_tsv: "Copy as TSV (for Excel)",
      copy_as_md_table: "Copy as markdown table",
      unsaved_changes_prompt: "There are unsaved changes on the page. Are you sure you want to leave?",

      menu: {
        title: "Title",
        url: "Link",
      },
  },
  currency_rates: {
      date: "Date",
      rate: "Rate",
      title: "Currency rates",
  },
  formcontrol: {
      markdown: {
          write: 'Edit',
          preview: 'Preview',
      }
  },
  fieldssettings: {
      title: "Fields settings",
      available: "Available fields",
      active: "Active fields",
      reset: "Reset",
  },
  fieldsfilters: {
      title: "Filters",
      emptyState: 'Click here or the "+" button above to add a filter',
      add: 'Add filter',
  },
  files: {
      pageTitle: "Files",
      
      filename: "File name",
      filetype: "Type",
      meta: "Properties",
      alias: "Alias",
      comments: "Comments",

      copyLink: "Copy file link",

      removeConfirmation: "Remove file?",
      editDialogTitle: "File",
      drop_or_click_to_upload: "Drop file here or click to select",
  },
  medialib: {
      title: "Media library",
      select: "Select a file",

      label: {
          space_id: "Space",
          _id: "ID",
          filename: "File name",
          contenttype: "Type",
          created_at: "Created",
          url: "URL",
      },

      browser: {
          no_files: "There are no files yet.",
          no_files_found: "No files found.",
          to_upload: "To upload a file click or just drag and drop file here.",

          link_copied: "The link has been copied to your clipboard",
          remove_file_confirmation: "Remove file?",

          download: "Download",
          remove: "Remove",
          replace: "Replace",
          details: "File details",
      },

      error: {
          dont_have_access_to_space: "You don't have access to this media library",
          filetypes_dont_match: "When replacing a file the types of the original and new file have to match",
          file_load_error: "There is a problem with the selected file. Try selecting a different one",
      },
  },
  statusActions: {
      action: 'Actions',
      cancel: 'Cancel'
  },
  notifications: {
      title: "Notifications",
      markasread: "Mark as read",
      markallasread: "Mark all as read",

      task: {
          title: "Title / subject",
          user_id: "User",
          email: "Email",
          kind: "Kind",
          tags: "Tags",
          display_name: "Name",
          info: "Info",
          text: "Notification / email text",
          message_html: "Notification / email HTML",
          attachments: "Attachments",
          email_template: "Email template",
          link: "Link (for internal notifications)",
          comment: "Comment",
          targets: "Targets",
          is_executed: "Executed",
          executed_time: "Executed at",

          controls: {
              title: "Notifications",
              title_one: "Notification",
              show_executed: "Show executed",
              execute: "Send",
              execute_confirmation: "Send notification?",
              test_execute: "Test",
              test_execute_hint: "We will send a test message to the specified email, substituting the parameters of the first target",
              test_execute_email_label: "Email",
              save_to_edit_targets: "Save the notification to edit targets",
              upload_targets: "Upload targets",
              download_targets_upload_example: "Download example",
              filter_by_tags: "Filter by tags:",
              filter_by_tags_mode_hint: "Filtering mode: OR - show targets, which have at least one of the selected tags; AND - show targets, which have all of the selected tags.",
              filter_by_tags_mode_or: "OR",
              filter_by_tags_mode_and: "AND",
              filter_by_tags_mode_not: "NOT",
              substitutions_hint: "Available substitutions: ",
              email_template_hint: "Email message will be injected into the {{_content_}} and {{_content_html_}} placeholders in the template. Click here to view email templates.",
              add_attachment: "Add attachment",
              fs_path_not_configured: "Can't get the path to the file. Please contact system administrator to ensure media library is configured properly",
          },

          target: {
              all: "All",
              add_filtered: "Add filtered",
              remove_filtered: "Remove filtered",
              remove_all: "Remove all",
              noone: "Add targets",
              count: "Total: {count} targets",
          },

          actions: {
            copy_and_send: {
              title: "Copy and send notification",
              description: "Copy and send notification task with the specified ID. Targets will be taken from the specified field of action data (can be a list of targets or a single target).",
              notification_task_id: "Notification ID",
              targets: "Targets",
              dont_save: "Don't save copy",
            },
          },

          calendar_event: {
            create_dialog_title: "Create iCal",

            fields: {
              start: "Start",
              end: "End",
              summary: "Title",
              id: "ID",
              timezone: "Time zone",
              description: "Description",
              location: "Location",
              organizer_name: "Organizer name",
              organizer_email: "Organizer Email",
            },
          },

          errors: {
            notification_task_id_missing: "Source notification task ID not specified",
            cant_execute_executed: "The notification is already processed",
            cant_delete_executed: "Sent notification can't be deleted",
            emailing_not_configured: "Emailing is not configured",
            no_targets: "Targets list is empty",
            targets_without_emails: "Targets don't have emails",
          },
      }
  },
  chat: {
      send: 'Send',
      edit: 'Edit',
      typeMessage: 'Message...',
  },
  errorPasswordsDontMatch: 'Passwords do not match',
  comments: {
      title: "Comments",
      newComment: "Add a comment (Ctrl+Enter to send)",
      deleteConfirmation: "Delete the comment?",
  },

  entity: {
      actions: {
          action_id: "ID",
          entity: "Entity",
          record: "Record",
          action: "Action",
          parameters: "Parameters",
          track: " ",
          user_id: "User",
          executed_at: "Time",
      },
  },
  dashboard: {
      update: 'Update',
      errors: {
          value_not_configured: 'Value field not selected for widget',
          query_not_configured: 'Query is not configured for the widget',
          details_query_not_configured: 'Details query is not configured for the widget',
          substitution_not_found_for_param: "Parameters or substitutions not set",
          unknown: 'An error occurred'
      },
      screen: {
          dash: 'Dashboard',
          widgets: 'Widgets',
          edit: 'Edit dashboards',
          create: 'Create a dashboard',
          params: 'Parameters & substitutions',
          type: 'Type',
          add: "Create widget",
          addexisting: "Add existing",
          pie: 'Pie chart',
          bar: 'Bar chart',
          counter: 'Counter',
          placeholder: 'Placeholder',
          save: 'Save',
          title: 'Title',
          code: 'Code',
          widgettype: 'Widget type',
          history_title: "Dashboard {title}",
      },
      widget: {
          dialog: 'Dashboard settings',
          title: 'Title',
          duplicate: 'Duplicate',
          subtitle: 'Subtitle',
          resultfields: 'Result fields',
          query: 'Query',
          detailsresultfields: 'Details fields',
          test_query: 'Test',
          label: 'Label',
          name: 'Name',
          link: 'Link',
          links_by_labels: 'Links',
          links_by_labels_hint: 'A single link for all values or several lines in the "value: link" format',
          empty_state_message: 'Empty state message',
          empty_state_message_hint: 'Displayed when there are no records that match the query',
          counter_colors: "Counter colors",
          counter_colors_hint: 'A single html color code or several lines in the "max-value: color-code" format',

          displaySettings: 'Display settings',
          show_pct: 'Show percentage',
          show_table: 'Show table',
          show_chart: 'Show chart',
          total_row: 'Total row',
          value_field: 'Value field',
          label_field: 'Label field',
          query_substitutions: 'Query string substitutions',
          query_substitutions_hint: "Format: let NAME = ... endlet; Then can be plugged into queries as ::NAME::",
          query_string: 'Query string',
          details_query_string: 'Details query string',
          remove: 'Remove widget',
          params: 'Parameters',

          param: {
              label: 'Label',
              default: 'Default value',
              code: 'Code',
              fieldtype: 'Field type',
              fieldtypes: {
                  number: 'Number',
                  text: 'Text',
              }
          },

          select_res_field: {
              fieldtype: 'Result field is just value',
              entity: 'Result field is entity from dictionary',
              fieldtype_label: 'Field type',
              number: 'Number',
              text: 'Text',
              percent: 'Percent',
              entity_label: 'Entity',
              entity_field: 'Entity field',
          }
      }
  },
  pages: {
      pageListTitle: "Pages",
      editPageTitle: "Edit Page",
      componentListTitle: "Components",
      components_guide: {
          title: "Pages content",
          general: `Every page consists of components - content blocks with their own display rules. You may create any number of components in a page - in particular, to split its content into blocks and simplify managing it.
          Components are show on the page in the ascending order of their 'Order' fields.
          When the type of a component is not specified, its content will be treated as a text in the Markdown format and will be rendered on the page as such. You can learn more about this format and its rules and capabilities using the link below:`,

          md_guide_url: "https://www.markdownguide.org/basic-syntax/",
          md_guide_text: "Markdown format description",

          part2: `The way other components are displayed as well as the requirements to their content depend on their types. The following component types are available in the system:`,

          type_descriptions: {
              markdown: "text in the Markdown format (see above)",
              documents: "documents download links, displayed with icons. Every line of the content should come in the following format (no quotes): 'link text; file download link; file name when downloaded'",
              conference: "a button to join a conference by link. The first line of the content must be the link, the second (optional) - a label to place on the button",
              tabs: "tabs with arbitrary content. Component content must be composed of text blocks in the following format: 1st line - ::tab, 2nd line - tab title, 3rd line and further - tab content in the markdown format",
          },

          part3: `When an unknown type is specified for a component, it will not be shown on the page. You may use that to temporary hide certain parts of a page.
          When the component must be hidden, simply specify arbitrary text as its type, and just change it back to the right type when you need to show that block again.`,
      },

      editComponentTitle: "Edit Component",
      deleteConfirmation: "Delete page?",
      page: {
          slug: "Slug",
          language: "Language",
          styles: "Styles",
          properties: "Properties"
      },
      component: {
          order: "Order",
          contenttext: "Content",
          subtypetext: "Type",
          properties: "Properties"
      }
  },

  images: {
      upload_image_hint: "Click to upload an image",
      upload: "Upload",
      delete: "Delete",
  },

  contact_form: {
      title: "Messages",

      labels: {
          message: "Message",
          user_id: "User",
          contact: "E-mail",
          is_processed: "Processed",
          is_discarded: "Spam",
          is_important: "Important",
          created_datetime: "Date",
      },
  },

  tags: {
      labels: {
          label: "Tag",
          color: "Color",
          label_plural: "Tags",
          count: "Usages count",
      },

      create: "Create tag",
      duplicate: "Duplicate tag",
      edit: "Edit tag",
      remove: "Delete tag?",
      remove_in_use: "The tag is in use. It will be detached from all records upon removal.",
      tag_usage: "Tag usage for ",
      collect_tags: "Collect tags",

      merge: {
          select_source: "Select a tag to merge",
          confirmation: "Merge tags?",
          execute: "Merge",
      },

      error: {
          cant_merge_no_tags: "Tags for merge not selected",
      },
  },

  system: {
      snapshots: {
          labels: {
              name: "Name",
              created_datetime: "Created",
          },

          title: "Snapshots",
          create_snapshot: "Create snapshot",
          switch_to_snapshot: "Switch",
      },
  },

  contests: {
      scoring: {
          sheets_list_title: "Scoring sheets",
          sheet_edit_title: "Scoring sheet: {title}",
          create_sheet: "Create a scoring sheet",
          sheet_preview: "Preview",
          sheet_numeric_total: "Total score is: {total}",

          fields: {
              sheet: {
                  code: "Code",
                  title: "Title",
                  description: "Description",
                  criteria: "Criteria",
              },
              criteria: {
                  name: "Name",
                  comment: "Comment/hint",
                  type: "Type",
                  min_value: "Minimum",
                  max_value: "Maximum",
                  options: "Options",
                  is_required: "Required field",
                  is_optional: "Optional",
                  reverse_values_order: "Reverse order",

                  custom: "Custom type",
                  use_chips: "Buttons",
              },
          },

          checkbox: {
              yes: "Yes",
              no: "No",
          },
      },

      rounds: {
          config: {
              title: 'Rounds',
              labels: {
                  configuration: "Configuration",
                  code: "Code",
                  has_playoff: "Playoff/knockout rounds",
                  playoff_stages_cnt: "Number of stages",
                  playoff_teams_required: "Number of teams required",
                  playoff_team_matching_rule: "Teams matching rule",
                  playoff_has_third_place_game: "3-rd place game",
                  playoff_round_title_final: "Final round title",
                  playoff_round_title_third: "3-rd place round title",
                  playoff_round_title_po: "Playoff round title",
                  
                  has_rating_rounds: "Rating rounds",
                  rating_group_kind: "Group type",
                  rating_group_kind_values: {
                      pairs_stable: "Pairs stable",
                      pairs_shift: "Pairs shifting",
                      triplets: "Triples",
                      custom: "Custom",
                  },

                  rating_grouping_rule: "Groups matching rule",
                  rating_grouping_rule_values: {
                      random: "Random",
                      sequential: "Sequential",
                      inward: "Inward",
                      halves: "Halves",
                  },
                  rating_player_rounds_cnt: "Number of rounds per team",
                  rating_round_title: "Rating round title",
                  players_accepted_cnt: "Number of teams accepted to the rounds",
                  rating_rounds_cnt: "Number of rating rounds",
                  round_title_hint: "Placeholders available: {stage} {number}",

                  avoid_player_conflicts: "Avoid conflicts between players",

                  arbiter_access_url: "Arbitrator link format",
                  player_access_url: "Team link format",
                  access_url_hint: "Placeholders available: {round_id}, {party_id}, {access_code}",

                  is_launched: "Launched",
                  launch: "Launch",
                  cancel: "cancel",
                  cancel_confirmation_title: "Cancel rounds?",
                  cancel_confirmation_explanation: "All created stages, rounds, assignments and scores will be removed. Continue?",

                  scoring_configuration: {
                    title: "Scoring configuration",
                    uses_default: "Default configuration is being used",
                    reset: "Reset to default",
                    modify: "Modify",
                    default_config: "Default (all stages)",
                    enable_other_stages: "Add specific config for a particular stage:",

                    stage: {
                      result_pick: "Result by",
                      result_aggregate: "Aggregation",
                      winner_pick: "Winner by",
                      winner_aggregate: "Aggregation",
                    },

                    score: {
                      key: "Key",
                      label: "Label",
                      type: "Type",
                      sheet: "Sheet",
                      extract_pick: "Fields",
                    },
                  },
              },

              stages: {
                  launch: "Launch",
                  launch_confirmation: "The stage will be launched, teams and arbitrators will gain access to the rounds",
                  launch_forced_confirmation: "There are problems in the rounds. Launch anyway?",
                  unlaunch: "Cancel launch",
                  unlaunch_confirmation: "Launch will be cancelled, access to rounds will be revoked.",
                  finish: "Finish",
                  calc_results: "Calculate results",
                  export: "Export data",
                  finish_confirmation: "The stage will be finished, scoring will be blocked, teams will be sent to the next stage, according to the results of this stage. Continue?",
                  finish_forced_confirmation: "There are problems in the rounds. Finish anyway?",
                  unfinish: "Resume",
                  unfinish_confirmation: "The stage will be resumed, changes to the teams' progress will be cancelled. Continue?",
                  validate: "Validate",
                  clear_problems: "Clear problems",
                  assign_players: "Assign teams",
                  assign_players_confirmation: "Existing team assignments will be overwritten. Continue?",
                  assign_players_by_order: "Import teams",
                  assign_players_by_order_details: "Enter teams into the field below as separate lines for every pair (you can paste this from Excel).\nE.g.:\n1 2\n3 4\n5 6\n\nWarning! Existing assignments will be overwritten!",
                  assign_arbiters: "Assign arbitrators",
                  assign_arbiters_confirmation: "Existing arbitrator assignments will be overwritten. Continue?",
                  assigning_to_n_rounds: "Will assign arbitrators to {n} round(s).",
                  include_tags: "Include by tags",
                  exclude_tags: "Exclude by tags",

                  arbiters_per_round: "Arbitrators per round",
                  max_assignments_per_arbiter: "Max assignments per arbitrator",
                  keep_order: "Don't shuffle",
                  keep_existing: "Keep existing assignments",

                  clear_arbiters: "Clear arbitrators",
                  clear_arbiters_confirmation: "All arbitrator assignments in this stage will be removed. Continue?",
                  clear_players: "Clear teams",
                  clear_players_confirmation: "All team assignments in this stage will be removed. Continue?",
                  clear_stage: "Re-create",
                  clear_stage_confirmation: "All assignments and all information on the rounds in this stage will be removed. Continue?",

                  qr_codes: "Get QR codes for scoring links",

                  edit_rounds: {
                      title: "Edit rounds",
                      rounds_selected: "Rounds selected: {count} ({nbrs})",
                      success_message: "Rounds saved",
                  },

                  view: "View",
                  view_short: "Simple",
                  view_detailed: "Full",
                  view_results: "Results",

                  timezone: {
                    switch_label: "Show time as",
                    local: "local",
                    utc: "UTC / no zone",
                  },

                  names: {
                      po_final: "Final",
                      po_1_2: "Semifinals",
                      po_1_4: "Quarterfinals",
                      po_1_8: "Eigth-finals",
                      po_1_16: "16th-finals",
                      rating: "Rating",
                  },

                  validation: {
                      rounds_with_problems: "There are problems in the rounds ## {value}",
                      players_unassigned: "Teams are not assigned",
                      arbiters_unassigned: "Arbitrators are not assigned",
                      conflicts: "There are conflicts",
                      conflicts_players: "There are conflicts between teams",
                      is_draw: "Draw",
                      missing_scores: "Missing scores: {arbiters}",
                      unequal_player_assignment: "Unequal teams assignment: {players}",
                      arbiters_time_clash: "Arbitrators are assigned to multiple rounds at the same time: {arbiters}. Rounds: {rounds}",
                      arbiters_time: "Arbitrators unavailable at that time: {arbiters}", 
                  },
              },

              validation: {
                  must_have_some_rounds: "Must have some rounds",
                  specify_playoff_stages_cnt: "Specify the number of playoff/kickout stages",
                  specify_players_accepted: "Specify the number of teams accepted to the rounds",
                  not_enough_players_for_playoff: "Not enough teams for playoff/kickout rounds",
                  players_count_doesnt_match_required_for_playoff: "The number of teams doesn't match required for the playoff/kickout rounds",
                  specify_rating_rounds_cnt: "Specify the number of rating rounds per team",
                  pairs_need_even_teams: "Number of teams must be even to match them in groups",
                  triplets_need_divisible3_teams: "Number of teams must be divisible by 3 to match them in triples",
                  triplets_must_have_2_rounds_per_team: "Must have exactly two rounds per team in triples",
                  cant_update_launched: "Can't update - the rounds are already launched",
                  problems_with_config: "Please fix the issues with the configuration",
                  already_launched: "Rounds are already launched",

                  arbiter_not_found: "Arbitrator not found",
                  cant_assign_player_against_itself: "Team can't play against itself",
                  cant_assign_the_same_arbiter_to_a_round_twice: "Arbitrator can't be assigned to the same round twice",
                  dont_know_how_to_create_multiple_rounds: "Invalid configuration: mutliple pairs matching rule",
                  next_stage_is_finished: "The next stage is finished - roll it back first",
                  only_two_rounds_in_triples: "Invalid configuration:  triples should have exactly two rounds per team",
                  player_not_found: "Team not found",
                  players_count_doesnt_match_required_for_rounds: "The number of teams doesn't match that required for rounds",
                  stage_has_problems: "There are problems with the stage",
                  stage_is_finished: "The stage is already finished",
                  stage_is_launched: "The stage is already launched",
                  stage_is_not_finished: "The stage was not finished yet",
                  stage_is_not_launched: "The stage was not launched yet",
                  unknown_matching_rule: "Invalid configuration: team matching rule",
              },
          },

          stage: {
              labels: {
                  kind: "Type",
                  is_launched: "Launched",
                  is_finished: "Finished",
              },
          },

          round: {
              edit: "Round {title}",
              labels: {
                  title: "Title",
                  number: "Number",
                  time: "Time",
                  description: "Description",
                  meeting_join_url: "Join link",
                  winner: "Winner",
                  arbiters: "Arbitrators",
                  vs: "vs",
                  player1: "Team 1",
                  player2: "Team 2",
                  player_unassigned: "not selected",
                  player1_score: "Team 1 result",
                  player2_score: "Team 2 result",
                  arbiter: "Arbitrator {position}",
                  arbiter_unassigned: "not selected",
              },
          },

          player_standing: {
              sort_order: "#",
              display_name: "Team",
              score: "Result",
              is_round_winner: "Won the round",
              wins: "Wins",
              losses: "Losses",
              pass: "Passes further",

              pass_yes: "Yes",
              pass_no: "No",
          },

          player: {
              select: "Select a team",
              labels: {
                  display_name: "Number",
                  description: "Description",
                  rounds_assigned: "Rounds assigned",
                  tags: "Tags",
              }
          },

          arbiter: {
              select: "Select an arbitrator",
              labels: {
                  display_name: "Name",
                  description: "Description",
                  rounds_assigned: "Rounds assigned",
              }
          },

          scores: {
              score: "Score",
              scored: "Scored",
              select_player: "Select",
              selected_player: "Selected",
          },

          ratings: {
              columns: {
                  position: "#",
                  display_name: "Team",
                  result: "Result",
                  scores_cnt: "Scores count",
                  scores: "All scores",
              }
          },

          public: {
              stage_names: {
                  po_final: "Final",
                  po_1_2: "Semifinals",
                  po_1_4: "Quarterfinals",
                  po_1_8: "8th-finals",
                  po_1_16: "16th-finals",
                  rating: "Rating rounds",
              },
              
              join_conference: "Join",
              copy_join_url: "Copy conference link",
              empty_state: "You will see your rounds here",
          }
      },

      registrations: {
          total: "Total registrations:",
          remove_hint: 'If you\'re sure you want to delete the record {description}, please type "delete" in the field below and press the "Delete" button. This action is irreversible!',
          labels: {
              approval: "Approval",
              actions: "Actions",
          },
          tabs: {
              all: "All",
              approved: "Approved",
              unapproved: "Unapproved",
          },
      },

      timeslots: {
        add: "Add slot",
        date: "Date",
        time: "Time",
      },

      join_conference: "Join conference",
  },


  forms: {
      page: {
          list_title: "Forms",
          create_form: "Create a form",
          form_page_title: "Form",
          config: "Configuration",
          actions: "Actions",
          submissions: "Submissions",
          delete_confirmation: "Delete form?",
          delete_submission_confirmation: "Delete submission?",
          add_field: "add field",
          non_default_locale_edit_fields_hint: "Switch to the default language to edit fields types",
          review_title: "Submission",
          change_field_id: "Change field ID",

          submission_view: {
              all: "All",
              new: "New",
              processed: "Processed",
          },
          submissions_total: "Total submissions: {total}",
      },

      form: {
          title: "Title",
          is_active: "Active",
          button_label: "Button label",
          on_submission_robud_program: "On submission execute",
          fields: "Fields",
      },

      field: {
          title: "Title",
          fieldtype: "Type",
          is_required: "Required",
          is_hidden: "Hidden",
          hint: "Hint",
          validation_regex: "Validation (regex)",
          options: "Options (one per line)",
          block_submit_if: "Block form if value is",
          hide_next_if: "Hide next fields if value is",
          block_submit_message: "Message when form is blocked",
          copy_code_hint: "Copy field code",
          actions: {
            on_submission: "On submission",
            manual: "Manual",
          },
          types: {
              text: "Text",
              text_multiline: "Multiline text",
              date: "Date",
              select_radio: "Select",
              select_multi: "Select multi",
              email: "E-mail",
              boolean: "Checkbox",
              number: "Number",
              file: "File",
              rank: "Rank",
          },
          regex_help: {
            phone: "Phone number validation",
            reference: "Regular expressions reference",
            reference_link: "https://www.regular-expressions.info/quickstart.html",
          },
      },

      submission: {
          created_at: "Created",
          lang: "Language",
          processing_status: "Status",
          comment: "Comment",
          download: "download",

          processing_status_value: {
              new: "New",
              processed: "Processed",
          },
      },

      editor: {
          insert: "Configurable form",
          form_selection_label: "Form",
          success_message: "Success message",
      },

      actions: {
        update_form_submission: {
          title: "Update submission",
          description: "Update form submission fields.\nInstructions syntax fields.by_idx.0 <- fields.email | fields.by_idx.0 = READY | processing_status = processed\nMay use {{placeholders}} in instructions right side values.",
          instructions_hint: "",
          form_id: "Form ID",
          submission_id: "Submission ID",
          skip_on_not_found: "Skip when not found",
        },
      },
  },

  powerdoc: {
      menu: {
          formatting: "Formatting",
          elements: "Elements",
          insert_item: "Insert",
      },

      format: {
          bold: "Bold",
          italic: "Italic",
          underline: "Underline",
          strikethrough: "Strike-through",
          highlight: "Highlight",
          code: "Code",
      },

      elements: {
          heading: "Heading {level}",
          paragraph: "Paragraph",
          ul: "List",
          ol: "Numbered list",
          new_line_after: "New line",
          delete_block: "Delete block",
          divider: "Divider",
          non_breaking_space: "Non-breaking Space",
      },

      align: {
          left: "Left",
          right: "Right",
          center: "Center",
          justify: "Justify",
      },

      pages: {
          title: "Documents",
          remove_document: "Delete document",
          new_document_title: "New document",
          placeholder: "Start typing…",
          document_title_placeholder: "Document title",
      },

      fields: {
          title: "Title",
          created_datetime: "Created",
      },

      plugins: {
          block_settings: {
              title: "Block configuration",
              dialog_title: "Block configuration ({block_type})",
          },

          columns: {
              title: "Columns",
              n_columns: "Columns",
          },

          conditional_block: {
              title: "Conditional block",
              condition: "Condition",
          },

          custom_fields_block: {
              title: "Custom fields",
              field: {
                  label: "Name",
                  value: "Value",
              },
          },

          expander: {
              title: "Expander",
          },

          gallery: {
              title: "Images",

              click_here_to_add: "click here to insert an image",
              dialog_title: "Insert image",
              image_url: "Image URL",
              insert: "Insert",
          },

          images: {
              title: "Image",
              dialog_title: "Image settings",

              fields: {
                  width: "Width",
                  height: "Height",
                  _class: "_class",
                  replace_image: "Replace image",
              },
          },

          links: {
              title: "Link",
              url: "URL",
              remove_link: "Remove link",
          },

          media_files: {
              title: "Images and documents",
              empty_state: "Click here to select or upload files",

              display_mode: {
                  title: "Display mode",
                  
                  gallery: "Gallery",
                  documents: "Documents",
                  slider: "Slider",
              },
          },

          panel: {
              title: "Panel",
          },

          placeholder: {
              title: "Placeholder",
              dialog_title: "Insert placeholder",
              expression: "Expression",
              mode: {
                  field: "Field",
                  expression: "Expression",
              },
          },

          script: {
              title: "Script",
          },

          table: {
              title: "Table",
              insert: {
                  row_before: "Insert row above",
                  row_after: "Insert row below",
                  column_before: "Insert column left",
                  column_after: "Insert column right",
              },
              remove: {
                  row: "Delete row",
                  column: "Delete column",
              },
          },

          template_block: {
              title: "Block",
              dialog_title: "Insert block",
          },

          tiles: {
              title: "Tiles",
              columns: "Columns: {columns}",
              tile: {
                  title: "Title",
                  content: "Text",
                  image_url: "Image",
                  link: "Link",
                  _class: "Class / properties",
              },
          },
      },
  },

  robud: {
      title: "Robud",

      program: {
          page_title: "Program",
          remove: "Delete the program?",
          program_removed: "The program was deleted",

          tabs: {
              program: "Configuration",
              triggers: "Triggers",
              executions: "Executions",
          },

          labels: {
              _id: "ID",
              title: "Title",
              data: "Data",
              operations: "Operations",
              created_at: "Created",
          },

          data: {
              kind: {
                  sql: "SQL",
                  constant: "Constant",
                  custom: "Custom",
              },
              labels: {
                  kind: "Kind",
                  script: "Query",
                  values: "Values",
                  values_hint: "as json array",
                  custom_name: "Type",
              }
          },

          operations: {
              add: "Add operation",
              kind: {
                  sql: "SQL",
                  email: "Email",
              },
              labels: {
                  kind: "Kind",

                  sql: {
                      script: "Script",
                  },
                  email: {
                      template: "Template",
                      user_email: "To (e-mail)",
                  },
              }
          },

          triggers: {
              add: "Add trigger",
              execute: "Run",
              remove: "Delete trigger?",

              kind: {
                  manual: "Manual",
                  schedule: "Schedule",
              },

              labels: {
                  trigger_id: "ID",
                  kind: "Mode",
                  is_active: "Active",

                  configuration: {
                      period: {
                          qty: "Period",
                          unit: " ",
                      },

                      calendar: {
                          at: "Time",
                          from: "From",
                          to: "Until",
                          dom: "Day of month",
                          dow: "Day of week",
                          dow_labels: {
                              "1": "Mon",
                              "2": "Tue",
                              "3": "Wed",
                              "4": "Thu",
                              "5": "Fri",
                              "6": "Sat",
                              "7": "Sun",
                          },
                      },
                  },
              },
          },
      },

      programs: {
          page_title: "Programs",
          create_program: "Create program",
      },

      executions: {
          page_title: "Execution log",
          see_log: "see log",
          details_title: "Execution details",
          copy_data: "Copy data",
          copy_results: "Copy results",
          copy_errors: "Copy errors",
          has_errors: "errors",

          filter: {
              after: "From",
              before: "To",
              reset: "Reset filter",
          },

          labels: {
              program_id: "Program",
              program_title: "Title",
              trigger_id: "Trigger",
              execution_id: "Execution",
              created_at: "Time",
              data: "Data",
              data_rows_nbr: "Data rows",
              results: "Results",
              errors: "Errors",
          },
      },

      error: {
          trigger_inactive: "The trigger is inactive and can't run",
          cant_delete_trigger_already_executed: "There were executions associated with this trigger - we can't remove it",
          substitution_not_found_for_param: "SQL: substitution not found for parameter",
          robud_inactive: "Robud is inactive - probably it got shut down due to some issue",
      },

      errors: {
          substitution_not_found_for_param: "SQL: substitution not found for parameter",
      }
  },

  cms: {
      title: "CMS",
      list: {
          new_record: "New record: {record_type}",
          all_languages: "all",
      },
      form: {
          publish: "Publish",
          remove: "Remove record?",
          translation_links: "Translations",
          translation_links_popup: "Select translation: {lang}",
          open_site: "Open in the site",
      },
      importexport: {
          title: "Import / Export",
          record_type: "Record type",
          record_type_label: " ",

          export_action: "Export",
          import_action: "Import",
          import_success: "Records successfully imported",
      },

      record: {
          fields: {
              _id: "ID",
              title: "Title",
              slug: "Slug",
              lang: "Language",
              record_datetime: "Date",
              created_datetime: "Created",
              published_datetime: "Published",
              content: "Content",
              attributes: "Attributes",
          }
      },

      editor: {
          insert_linked_records: "Linked records",
          linked_records_empty: "Select records",
      },


      error: {
          record_type_not_found: "Unknown type of record",
          link_type_not_found: "Unknown type of link",
          unknown_language: "Unknown language",
          slug_is_required: "The slug is required",
          slug_in_use: "This slug is already in use ({slug}, {record_type})",
          unknown_link_kind: "Unknown link kind",
          cant_link_record_to_itself: "Can't link a record to itself",
          cant_have_more_than_one_translation_per_language: "Can't add more than one translation per language",
      },
  },

  documentation: {
      create_doc: {
          insert: "Insert document",
          dialog_title: "Create document",
          document_title: "Title",

          mode: {
              new: "New",
              existing: "Existing",
          },
      },
  },

  extras: {
    actions: {
      fields: {
        action_type: "Type",
        action_params: "Parameters",
        label: "Title",
      },

      types: {
        action_chain: {
          title: "Action chain",
          description: "Execute a series of actions one after the other",
          actions: "Actions",
        },
        enrich_data: {
          title: "Enrich data",
          description: "Add/replace fields in data.\nInstructions syntax: field.in.result <- field.in.data || default or field.in.result = value",
          instructions: "Instructions",
          instructions_hint: "you may specify several instructions on separate lines",
        },
        log_data: {
          title: "Log data",
          description: "Logs full incoming data to app logs",
          message: "Log message",
        },
      },

      triggers: {
        edit_triggers_actions_title: "Actions",
      },
    },

    mass_action: {
      dialog_title: "Execute action: {label}",
      execute: "Launch",
    },
  },
  
  features: {
      switches: {
          title: "Features",
      },
  },
}