import React from 'react';
import styled from '@emotion/styled';
import { Typography, withTheme } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { PageContainer } from '../../../Pages';
import { LoadingIndicator } from '../../../primitives/LoadingIndicator';
import { useUser } from '../../../../userContext/UserContext';
import { PublicResults, usePublicResults } from './usePublicResults';
import { TableForFields } from '../../../schemed';
import { StarBorder } from '@material-ui/icons';

interface Props {
    data: PublicResults;
    orderStartToFinal?: boolean;
}

const SelfIndicator = withTheme(styled(StarBorder)`
    padding-left: 0.25rem;
    font-size: inherit;
    color: ${props => props.theme.palette.primary.main};
`);

const Wrapper = styled.div`
    & > h6 {
        margin-top: 1rem;
    }

    & > :last-child {
        margin-bottom: 1rem;
    }

    & th:first-child {
      width: 36px;
    }
    & th:last-child {
      width: 200px;
    }
`;

const StagesOrder = [
  "po_final",
  "po_1_2",
  "po_1_4",
  "po_1_8",
  "po_1_16",
  "rating",
];

export const PublicResultsForm = (props: Props) => {
    const { user } = useUser();
    const { data, isLoading, schema } = props.data;
    
    const sortedStages = data.sort((a,b) => (props.orderStartToFinal ? 1 : -1) * ((StagesOrder.indexOf(a.stage_code) || 0) < (StagesOrder.indexOf(b.stage_code) || 0) ? 1 : -1))

    return <Wrapper>
        {isLoading && <LoadingIndicator />}
        {sortedStages.map(({ stage_code, standing}) => (<React.Fragment key={stage_code}>
            <Typography variant="h6" key={stage_code}><FormattedMessage id={`contests.rounds.public.stage_names.${stage_code}`} /></Typography>
            <TableForFields
                data={standing}
                schema={schema}
                fields={[
                    ["sort_order"],
                    ["display_name"],
                    ["score", { precision: 2 }],
                    (stage_code === "rating" ? ["__", { label: " " }] : ["is_round_winner"]),
                ]}
                fieldElement={f => {
                    if(f === "display_name") {
                        return (r,_,o) => <span>{o} {r.party_id === user?._id && <SelfIndicator />}</span>;
                    }
                    if(f === "sort_order") {
                        return r => <>{r.sort_order + 1}</>;
                    }
                }}
            />
        </React.Fragment>))}
        </Wrapper>
}

interface ContainerProps extends Omit<Props, 'data'> {
    apiPath: string;
    kind: 'player' | 'arbiter';
    pageApiPath: string;
    pageSlug: string;
}

/**
 * Public grid results page for players and arbitrators.
 * The results table will be plugged in place of the page component of custom type `results`
 * @param props 
 * @returns 
 */
export const PublicResultsContainer = (props: ContainerProps) => {
    const data = usePublicResults(props.apiPath, props.kind);
    const results = <PublicResultsForm {...props} data={data} />

    return (
        <PageContainer
            apiPath={props.pageApiPath}
            slug={props.pageSlug}
            customComponents={{
                results: () => results,
            }}
        />
    );
    
}
