import { Button, Chip, Typography } from '@material-ui/core';
import { Check, WarningOutlined } from '@material-ui/icons';
import React from 'react';
import { ActionRow, FormGrid, OccupyFreeSpace } from '../../toolympus/components/primitives';
import { useSaveable } from '../../toolympus/components/primitives/useSaveable';
import { ContestState, useContestState } from '../../useContestState';
import { TimeslotsWrapper } from '../Admin/Control/TimeslotsConfig';
import { formatSlotDayDisplay, formatSlotTimeDisplay, timeslotToDate, useViewTimeslots } from '../Admin/Control/useTimeslotsConfig';
import { Divider } from '../Common.styles';
import { JudgeProfile } from './useJudgeProfile';

interface UnboundProps {
    contestState: ContestState;
    timeslots: string[] | null | undefined;
    update: (slots: string[]) => void;
    hasChanges: boolean;
    save: () => void;
}

export const JudgeTimeslotsUnboundSelection = (props: UnboundProps) => {
    const { contestState, update, hasChanges, save } = props;

    const timeslots = props.timeslots || [];
    const toggleSelection = (slot: string) => {
        if(!contestState.judges_timeslots_enabled) {
            return;
        }
        
        if(timeslots.includes(slot)) {
            update(timeslots.filter(s => s !== slot));
        } else {
            update([...timeslots, slot]);
        }
    }

    const { days, timeslotsByDay } = useViewTimeslots(contestState.rounds_timeslots);


    if(!contestState.judges_timeslots_available || !contestState.rounds_timeslots || !contestState.rounds_timeslots.length) {
        return null;
    }

    return (<>
        <div>
            <div style={{ display: "flex", alignItems: "center", gap: "0.5rem"}}>
                {timeslots.length === 0 && <WarningOutlined color="error" />}
                <Typography style={{ fontWeight: 500 }}>
                    Пожалуйста укажите, в какое время вы сможете участвовать в итоговых раундах:
                </Typography>
            </div>
            <FormGrid columns="max-content 1fr" style={{ alignItems: "center" }}>
                {days.map(d => <React.Fragment key={d.toString()}>
                    <Typography>{formatSlotDayDisplay(d)}</Typography>

                    <TimeslotsWrapper>
                        {timeslotsByDay[d.toString()].map(ts => {
                            const isSelected = timeslots.includes(ts);
                            return (
                                <Chip
                                    icon={isSelected ? <Check /> : undefined}
                                    label={formatSlotTimeDisplay(ts)}
                                    color={isSelected ? "primary" : "default"}
                                    variant={isSelected ? "default" : "outlined"}
                                    onClick={() => toggleSelection(ts)}
                                    />
                            )
                            })}
                    </TimeslotsWrapper>

                </React.Fragment>)}

            </FormGrid>

            <ActionRow firstItemNoMargin lastItemMarginRight="0">
                <Typography variant="caption">Указано московское время</Typography>
                <OccupyFreeSpace />
                {hasChanges && <Button color="primary" variant="contained" onClick={() => save()}>сохранить</Button>}
            </ActionRow>
        </div>
        <Divider />
    </>);
}


interface Props {
    profile: JudgeProfile;
}

export const JudgeTimeslotsSelection = ({ profile }: Props) => {
    useSaveable(profile);

    const contestState = useContestState();

    return (
        <JudgeTimeslotsUnboundSelection
            contestState={contestState.data}
            hasChanges={profile.hasChanges}
            save={() => profile.save()}
            timeslots={profile.data.timeslots_selected}
            update={slots => profile.update({ timeslots_selected: slots })}
            />
    );
}


export const TimeslotsDisplay = (props: { slots: string[] | null | undefined }) => {
    const slots = (props.slots || []).sort();
    
    const byDay = slots.reduce<Record<string, string[]>>((r,s) => {
        const day = timeslotToDate(s);
        r[day] = [...(r[day] || []), s];
        return r;
    },
    {});

    return <>
        {Object.entries(byDay).map(([day, slots]) => (
            <Typography>
                <strong>{formatSlotDayDisplay(slots[0])}:</strong> {slots.map(s => formatSlotTimeDisplay(s)).join(", ")}
            </Typography>))}
    </>
}
