import React, { ReactNode } from 'react';
import { Buttons, Dialog, DialogState, FormGrid, OccupyFreeSpace } from '../../primitives';
import { FormattedMessage } from 'react-intl';
import { GridConfiguration, GridScoreItem, GridStageScoringConfig, GridStageT, GridStages } from './useGridConfiguration';
import { Button, Chip, Divider, IconButton, Typography } from '@material-ui/core';
import { FormControlsForFields } from '../../schemed';
import { FieldType, Schema } from '../../../hooks/useSchema';
import { generateCode } from '../../PowerDoc/plugins/common';
import { ListEditor } from '../../primitives/ListEditor';
import { DeleteOutlined } from '@material-ui/icons';

interface Props extends DialogState {
  data: GridConfiguration;
  update: (c: Partial<GridConfiguration>) => void;
}

const StageSchema: Schema = {
  result_pick: { label_id: "contests.rounds.config.labels.scoring_configuration.stage.result_pick" },
  result_aggregate: {
    label_id: "contests.rounds.config.labels.scoring_configuration.stage.result_aggregate",
    type: FieldType.select,
    values: ["sum", "avg", "avg-drop-outlier"].map(value => ({ value, label: value })),
  },
  winner_pick: { label_id: "contests.rounds.config.labels.scoring_configuration.stage.winner_pick" },
  winner_aggregate: {
    label_id: "contests.rounds.config.labels.scoring_configuration.stage.winner_aggregate",
    type: FieldType.select,
    values: ["sum", "avg", "avg-drop-outlier"].map(value => ({ value, label: value })),
  },
}

const ScoreSchema: Schema = {
  key: { label_id: "contests.rounds.config.labels.scoring_configuration.score.key" },
  label: { label_id: "contests.rounds.config.labels.scoring_configuration.score.label" },
  type: {
    label_id: "contests.rounds.config.labels.scoring_configuration.score.type",
    type: FieldType.select,
    values: ["player-sheet", "player-select", "player-info"].map(value => ({ value, label: value })),
  },
  sheet: { label_id: "contests.rounds.config.labels.scoring_configuration.score.sheet" },
  extract_pick: { label_id: "contests.rounds.config.labels.scoring_configuration.score.extract_pick" },
  aggregate: {
    label_id: "contests.rounds.config.labels.scoring_configuration.stage.result_aggregate",
    type: FieldType.select,
    values: ["sum", "avg", "avg-drop-outlier"].map(value => ({ value, label: value })),
  },
}

const createDefaultScore = (): GridScoreItem => ({
  _id: generateCode(),
  key: "x1",
  label: "",
  type: "player-sheet",
});

const useEditScoringConfiguration = (data: Pick<Props, "data" | "update">) => {
  const configuration = data.data.scoring_config || {};
  const update = (c: Partial<GridConfiguration["scoring_config"]>) => data.update({ scoring_config: { ...(data.data.scoring_config || {}), ...c }});

  const isDefault = Object.keys(configuration).length === 0;

  const initializeNew = () => {
    update({
      default: {
        scores: [createDefaultScore()],
      },
    })
  }

  const initializeStage = (stage: GridStageT) => {
    update({
      [stage]: {
        scores: [createDefaultScore()],
      }
    })
  }

  const removeStage = (stage: GridStageT) => {
    const withRemoved = { ...(data.data.scoring_config || {}) };
    delete withRemoved[stage];
    data.update({ scoring_config: withRemoved });
  }

  const reset = () => {
    data.update({ scoring_config: null });
  }

  const updateStage = (stage: GridStageT | "default", c: Partial<GridStageScoringConfig>) => {
    update({ [stage]: { ...(configuration[stage] || {}), ...c }});
  }

  const updateStageScore = (stage: GridStageT | "default", scoreIdx: number, changes: Partial<GridScoreItem>) => {
    updateStage(stage, { scores: (configuration[stage]?.scores || []).map((s,i) => i === scoreIdx ? { ...s, ...changes } : s)});
  }

  return {
    configuration,
    update,
    updateStage,
    updateStageScore,
    initializeStage,
    removeStage,

    isDefault,
    initializeNew,
    reset,
  }
}


const ScoreEditor = (props: { item: GridScoreItem, update: (c: Partial<GridScoreItem>) => void }) => {
  return (
    <FormGrid noMargin columns="1fr 1fr 1fr 1fr 2fr">
      <FormControlsForFields
        data={props.item}
        onChange={(o,c) => props.update(c)}
        schema={ScoreSchema}
        fields={[
          ["key"],
          ["label"],
          ["type"],
          ["aggregate"],
          props.item.type === 'player-sheet' && ["sheet"],
          props.item.type === 'player-info' && ["extract_pick"],
        ]}
        />
    </FormGrid>
  )
}

interface StageEditProps {
  label?: ReactNode;
  data: GridStageScoringConfig | undefined;
  update: (c: Partial<GridStageScoringConfig>) => void;
  remove?: () => void;
}

const StageScoreEditor = (props: StageEditProps) => {
  return (<>
    {(props.label || props.remove) && (
      <Buttons>
        {props.label && <Typography variant="h6">{props.label}</Typography>}
        <OccupyFreeSpace />
        {props.remove && <IconButton size="small" onClick={() => props.remove && props.remove()}><DeleteOutlined /></IconButton>}
      </Buttons>
    )}
    <FormGrid columns="1fr 1fr 1fr 1fr" noMargin>
      <FormControlsForFields
        data={props.data || {}}
        onChange={(o,c) => props.update(c)}
        schema={StageSchema}
        fields={[
          ["result_pick"],
          ["result_aggregate"],
          ["winner_pick"],
          ["winner_aggregate"],
        ]}
        />
    </FormGrid>

    <ListEditor
      itemEditor={ScoreEditor}
      idField="_id"
      createDflt={createDefaultScore}
      update={scores => props.update({ scores })}
      items={(props.data?.scores || [])}
      />
  </>)
}


export const ScoringConfigurationDialog = (props: Props) => {
  const data = useEditScoringConfiguration(props);
  return (
    <Dialog
      {...props}
      dialogTitle={<FormattedMessage id="contests.rounds.config.labels.scoring_configuration.title" />}
      titleActions={<>
        {!data.isDefault && <Button size="small" onClick={() => data.reset()}><FormattedMessage id="contests.rounds.config.labels.scoring_configuration.reset" /></Button>}
      </>}
      maxWidth="md"
      fullWidth
      noFullscreen
      >

      {data.isDefault &&
        <Typography><FormattedMessage id="contests.rounds.config.labels.scoring_configuration.uses_default" /></Typography>}
      {data.isDefault &&
        <Button variant="contained" color="primary" onClick={() => data.initializeNew()}>
          <FormattedMessage id="contests.rounds.config.labels.scoring_configuration.modify" />
        </Button>}


      {!data.isDefault && <>

        <StageScoreEditor
          label={<FormattedMessage id="contests.rounds.config.labels.scoring_configuration.default_config" />}
          data={data.configuration?.default}
          update={c => data.updateStage("default", c)}
          />


        {GridStages.filter(stage => !!data.configuration[stage]).map(stage => (<React.Fragment key={stage}>
          <Divider style={{ margin: "1rem 0"}} />
          <StageScoreEditor
            label={<FormattedMessage id={`contests.rounds.config.stages.names.${stage}`} />}
            data={data.configuration[stage]}
            update={c => data.updateStage(stage, c)}
            remove={() => data.removeStage(stage)}
            />
          </React.Fragment>))}


        <Divider style={{ margin: "1rem 0"}} />

        {GridStages.filter(stage => !data.configuration[stage]).length > 0 && <Typography><FormattedMessage id="contests.rounds.config.labels.scoring_configuration.enable_other_stages" /></Typography>}

        <Buttons>
          {GridStages.filter(stage => !data.configuration[stage]).map(stage => (
            <Chip onClick={() => data.initializeStage(stage)} label={<FormattedMessage id={`contests.rounds.config.stages.names.${stage}`} />}/>
            ))}
        </Buttons>

      </>}
      
    </Dialog>
  );
}
